import React, { Fragment, useState } from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useCalendlyEventListener, PopupModal } from 'react-calendly'
import { Button } from 'antd'
import { Navbar } from '../components/navbar'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer/footer.component'

import '../css/faqs.scss'
import CustomButton from '../components/custom-button/custom-button.component'
import tracking from '../utils/tracking'

const FAQs = ({ data }) => {
	const [show, setShow] = useState(false)

	const handleClose = () => setShow(false)

	const handleShow = () => {
		setShow(true)
		// openPopupWidget({ url: 'https://calendly.com/icloudready/request-discovery' })
		tracking.eventTrack('interaction', 'click', 'request_discovery')
	}

	useCalendlyEventListener({
		onEventScheduled: event => {
			// console.log('Event scheduled: ', event)
			tracking.trackConversion('request_meeting', event)
			navigate('/meeting-request-received')
		},
	})

	return (
		<Fragment>
			<Layout>
				<SEO title="FAQs" description="Frequently Asked Question" />
				<div className="realestate-page">
					<Navbar />
					<div className="faqs-container" style={{ paddingTop: '80px' }}>
						<header>
							<h1>FAQ</h1>
							<p style={{ marginBottom: 0 }}>Frequently Asked Questions:</p>
						</header>
						<section className="faqs-content">
							<MDXRenderer>{data.FAQS.nodes[0].body}</MDXRenderer>
						</section>
					</div>
					{/* <footer>
				<article>
					<h3>Still have a question?</h3>
					<p>Our support staff are ready to help with any technical issues.</p>
				</article>
			</footer> */}
					<Footer
						title="1390 Market Street, San Francisco, California 94102, United States"
						rights="Copyright © 2021 icloud-ready - All Rights Reserved."
						bookingBtn={<CustomButton onClick={handleShow}>Book Demo</CustomButton>}
					/>
				</div>
			</Layout>
			<PopupModal
				url="https://calendly.com/icloudready/request-discovery"
				open={show}
				onModalClose={handleClose}
				rootElement={typeof window !== 'undefined' ? window.document.body : null}
			/>
		</Fragment>
	)
}

export default FAQs

export const pageQuery = graphql`
	query faqsQuery {
		FAQS: allMdx(filter: { frontmatter: { title: { eq: "FAQs" } } }) {
			nodes {
				body
			}
		}
	}
`
